import { useContext } from 'react';
import PageTitle from '../components/page-title';
import Page from '../components/page';
import Breadcrumbs from '../components/breadcrumbs';
import { Context } from '../store/user';
import { currencyFormat, dateFormat } from '../lib/number';
import '../components/investments-table.scss';
import checkmark from '../images/check-solid.svg';

const breadcrumbs = [
  {
    to: '/account',
    label: 'Account',
  },
  {
    to: '/investments',
    label: 'Investments',
  },
];

const Investments = () => {
  const [state] = useContext(Context);
  const { userData } = state;
  const {
    totalInvested,
    totalAccruedInterest,
    totalInvestmentValue,
    investments,
  } = userData;

  return (
    <>
      <PageTitle
        title="My Investments"
      />
      <Page>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h2 className="title is-size-2 mb-6">Portfolio</h2>
        { (!investments || investments.length < 1) ? (
          <p>You currently have no investments.</p>
        ) : (
          <>
            <div className="columns">
              <div className="column">
                <div>
                  <p className="heading">Total Invested</p>
                  <p className="title">{currencyFormat(totalInvested)}</p>
                </div>
              </div>
              <div className="column">
                <div>
                  <p className="heading">Total Accrued Interest</p>
                  <p className="title">{currencyFormat(totalAccruedInterest)}</p>
                </div>
              </div>
              <div className="column">
                <div>
                  <p className="heading">Total Investment Value</p>
                  <p className="title">{currencyFormat(totalInvestmentValue)}</p>
                </div>
              </div>
            </div>
            <hr />
            <table className="table investments-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date Invested</th>
                  <th>Date Of Maturity</th>
                  <th>Amount</th>
                  <th>Interest Rate</th>
                  <th>Accrued Interest</th>
                  <th>Total Value</th>
                  <th>Funds Repaid</th>
                </tr>
              </thead>
              <tbody>
                { investments.map((investment) => {
                  const {
                    investment_date: dateInvested,
                    investment_date_of_maturity_group: dateOfMaturityGroup,
                    investment_amount: amount,
                    investment_accrued_interest: accruedInterest,
                    investment_total_value: totalValue,
                    investment_bond: investmentBond,
                    funds_repaid: fundsRepaid,
                  } = investment;

                  const {
                    post_title: name,
                    interest_rate: interestRate,
                  } = investmentBond;

                  const {
                    investment_date_of_maturity: dateOfMaturity,
                    override_investment_date_of_maturity: overrideDateOfMaturity,
                    overridden_investment_date_of_maturity: overriddenDateOfMaturity,
                  } = dateOfMaturityGroup;

                  return (
                    <tr key={`${name}-${dateInvested}`}>
                      <th>{ name }</th>
                      <td>{ dateFormat(dateInvested) }</td>
                      <td>
                        { overrideDateOfMaturity
                          ? dateFormat(overriddenDateOfMaturity)
                          : dateFormat(dateOfMaturity) }
                      </td>
                      <td>{ currencyFormat(amount) }</td>
                      <td>{ `${interestRate}%` }</td>
                      <td>{ currencyFormat(accruedInterest) }</td>
                      <td><strong>{ currencyFormat(totalValue) }</strong></td>
                      <td>{ fundsRepaid && <img style={{ width: 18, marginTop: 3 }} src={checkmark} alt="Funds repaid" /> }</td>
                    </tr>
                  );
                }) }
              </tbody>
            </table>
            <div className="block">
              <small style={{ color: '#aaa' }}>
                The accrued interest outlined above is for guidance purposes only,
                the actual amount payable on your investment will be confirmed to you in
                your quarterly or final reconciliation statement.
              </small>
            </div>
          </>
        ) }
      </Page>
    </>
  );
};

export default Investments;
