import { useContext } from 'react';
import { isEmpty, map } from 'lodash';
import PageTitle from '../components/page-title';
import Page from '../components/page';
import Breadcrumbs from '../components/breadcrumbs';
import { Context } from '../store/user';
import DocumentsList from '../components/documents';
import PdfDocuments from '../components/pdf-documents';

const breadcrumbs = [
  {
    to: '/account',
    label: 'Account',
  },
  {
    to: '/documents',
    label: 'Documents',
  },
];

const Documents = () => {
  const [state] = useContext(Context);
  const { userData } = state;
  const {
    investmentDocuments,
    kycAmlDocuments,
    messagesUpdatesDocuments,
    projectUpdates,
    quarterlyStatements,
    taxCertificates,
  } = userData;

  const noDocs = isEmpty(investmentDocuments)
  && isEmpty(kycAmlDocuments)
  && isEmpty(messagesUpdatesDocuments)
  && isEmpty(projectUpdates)
  && isEmpty(quarterlyStatements)
  && isEmpty(taxCertificates);

  return (
    <>
      <PageTitle
        title="My Documents"
      />
      <Page>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className="block content">
          { noDocs && <p>You currently have no documents.</p> }
          <div className="columns">
            { projectUpdates && projectUpdates.length > 0 && (
              <div className="column">
                <div className="box" style={{ height: '100%' }}>
                  <h2 className="is-size-4">Project Updates</h2>
                  <PdfDocuments documents={map(projectUpdates, (projectUpdate) => ({
                    title: projectUpdate.project_update_title,
                    file: projectUpdate.project_update_file,
                  }))}
                  />
                </div>
              </div>
            ) }
            { quarterlyStatements && quarterlyStatements.length > 0 && (
              <div className="column">
                <div className="box" style={{ height: '100%' }}>
                  <h2 className="is-size-4">Quarterly Statements</h2>
                  <PdfDocuments documents={map(quarterlyStatements, (quarterlyStatement) => ({
                    title: quarterlyStatement.quarterly_statement_title,
                    file: quarterlyStatement.quarterly_statement_file,
                  }))}
                  />
                </div>
              </div>
            ) }
            { taxCertificates && taxCertificates.length > 0 && (
              <div className="column">
                <div className="box" style={{ height: '100%' }}>
                  <h2 className="is-size-4">Tax Certificates</h2>
                  <PdfDocuments documents={map(taxCertificates, (taxCertificate) => ({
                    title: taxCertificate.tax_certificate_title,
                    file: taxCertificate.tax_certificate_file,
                  }))}
                  />
                </div>
              </div>
            ) }
          </div>
          <div className="columns">
            { investmentDocuments && investmentDocuments.length > 0 && (
              <div className="column">
                <div className="box" style={{ height: '100%' }}>
                  <h2 className="is-size-4">Investment Documents</h2>
                  <DocumentsList documents={investmentDocuments} />
                </div>
              </div>
            ) }
            { kycAmlDocuments && kycAmlDocuments.length > 0 && (
              <div className="column">
                <div className="box" style={{ height: '100%' }}>
                  <h2 className="is-size-4">KYC / AML Documents</h2>
                  <DocumentsList documents={kycAmlDocuments} />
                </div>
              </div>
            ) }
            { messagesUpdatesDocuments && messagesUpdatesDocuments.length > 0 && (
              <div className="column">
                <div className="box" style={{ height: '100%' }}>
                  <h2 className="is-size-4">Messages / Updates</h2>
                  <DocumentsList documents={messagesUpdatesDocuments} />
                </div>
              </div>
            ) }
          </div>
        </div>
      </Page>
    </>
  );
};

export default Documents;
