import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AuthService from '../services/auth-service';
import FormContainer from './form-container';
import { Context } from '../store/user';
import { getUserData } from '../api/api';
import CookieService from '../services/cookie-service';

const LoginForm = withRouter(({ redirectPath, history }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(Context);
  const [showError, setShowError] = useState(false);
  const auth = new AuthService();
  const fields = [
    {
      id: 'login-form-email',
      type: 'email',
      required: 'true',
      label: 'Email',
      placeholder: 'e.g. alice.smith@company.com',
      helpText: 'Please enter a valid email address',
    },
    {
      id: 'login-form-password',
      type: 'password',
      required: 'true',
      label: 'Password',
      placeholder: 'Your password',
      helpText: 'Please enter a password',
    },
  ];

  const formSubmitCallback = async (formValues) => {
    setShowError(false);

    const signedIn = await auth.signIn(formValues[fields[0].id], formValues[fields[1].id]);
    if (signedIn) {
      // Use token to fetch userData
      if (signedIn.data && signedIn.data.token) {
        const userData = await getUserData(signedIn.data.token);

        // Set WP login cookies
        CookieService.setCookie(`wordpress_logged_in_${signedIn.data.hash}`, signedIn.data.loggedInCookie, 1, process.env.REACT_APP_WP_API_COOKIE_DOMAIN);
        CookieService.setCookie(`wordpress_sec_${signedIn.data.hash}`, signedIn.data.secCookie, 1, process.env.REACT_APP_WP_API_COOKIE_DOMAIN);

        // Set user in global context
        dispatch({
          type: 'LOGIN',
          payload: {
            ...userData,
            cookieHash: signedIn.data.hash,
          },
        });

        history.push(redirectPath);
      } else {
        // something went wrong
        setShowError(true);
      }
    } else {
      // something went wrong
      setShowError(true);
    }
  };

  return (
    <FormContainer
      fields={fields}
      onSubmitCallback={formSubmitCallback}
      submitButtonText="log in"
      errorMessage="Sorry we couldn't log you in. Please check your details and try again."
      showErrorMessage={showError}
    />
  );
});

LoginForm.propTypes = {
  redirectPath: PropTypes.string,
};

LoginForm.defaultPropts = {
  redirectPath: '/',
};

export default LoginForm;
