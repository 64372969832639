import { useContext } from 'react';
import { format } from 'date-fns';
import { Context } from '../store/user';

const currencyFormat = (number) => {
  const [state] = useContext(Context);
  const { userData } = state;
  const {
    investorCurrencySymbol,
  } = userData;

  return (number ? `${investorCurrencySymbol}${parseFloat(number).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '');
};

const dateFormat = (date) => format(new Date(date), 'dd/MM/yyyy');

export { currencyFormat, dateFormat };
